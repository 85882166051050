import PropTypes from "prop-types";
import Photo from "components/home/Photo";
import Title from "components/shared/adventures/Title";
import styles from "components/home/WslEvent.module.scss";
import IconArrowRightSm from "solar/icons/IconArrowRightSm";
import { useRouter } from "next/router";
import { formatShortDateRange } from "helpers/formatting";
import cn from "classnames";

export default function WslEvent({ href = "", name, location, photo, startDate, endDate }) {
  const router = useRouter();

  function handleClick() {
    if (!href) {
      window._klOnsite.openForm(["V9jXuR"]); // eslint-disable-line no-underscore-dangle
    } else {
      router.push(href);
    }
  }

  return (
    <div className={styles.card}>
      <button
        type="button"
        onClick={handleClick}
      >
        <Photo rounded photo={photo} className={cn(styles.photo, !href && styles.grayed)} />
        <div className={styles.overlay} />
        <div className={styles.content}>
          <div className={styles.top}>
            <p className={styles.location}>{location}</p>
            <p className={styles.dates}>{formatShortDateRange(startDate, endDate)}</p>
            <Title tag="h2" className={styles.name}>
              {name}
            </Title>
          </div>
          <div className={styles.bottom}>
            {!href ? (
              <div className={styles.cta}>Coming soon</div>
            ) : (
              <div className={styles.cta}>
                Explore&nbsp;
                <IconArrowRightSm />
              </div>
            )}
          </div>
        </div>
      </button>
    </div>
  );
}

WslEvent.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  photo: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    blurDataURL: PropTypes.string,
    blurWidth: PropTypes.number,
    blurHeight: PropTypes.number,
  }).isRequired,
};

import Container from "components/shared/adventures/Container";
import Introduction from "components/home/Introduction";
import WslEvent from "components/home/WslEvent";
import styles from "components/home/Wsl.module.scss";

import photoLexusPipePro from "public/home/wsl/lexus.jpg";
import photoPeniche from "public/home/wsl/peniche.jpg";
import photoSurfCity from "public/home/wsl/surf-city.jpg";
import photoTrestles from "public/home/wsl/trestles.jpg";
import photoTahiti from "public/home/wsl/tahiti.jpg";
import photoFiji from "public/home/wsl/fiji.jpg";

export default function Wsl() {
  return (
    <div className={styles.component}>
      <Container>
        <Introduction
          title={
            <img
              src="/wsl/wsl-thermal-logo-lockup-white.png"
              alt="WSL x Thermal"
              width="320"
              height="40"
            />
          }
          text="Exclusive, VIP experience at World Surf League competitive events with access to the VIP area, meet WSL athletes, and surfing with former WSL professional surfers as guides."
        />
        <div className={styles.grid}>
          <WslEvent
            location="O'ahu"
            name="Lexus Pipe Pro"
            href="/wsl/lexus-pipe-pro"
            photo={photoLexusPipePro}
            startDate="2025-01-31"
            endDate="2025-02-06"
          />
          <WslEvent
            location="Peniche"
            name="MEO Rip Curl Pro Portugal"
            href="/wsl/meo-rip-curl-pro"
            photo={photoPeniche}
            startDate="2025-03-16"
            endDate="2025-03-22"
          />
          <WslEvent
            location="La Libertad"
            name="Surf City El Salvador Pro"
            href="/wsl/surf-city-el-salvador-pro"
            photo={photoSurfCity}
            startDate="2025-04-03"
            endDate="2025-04-10"
          />
          <WslEvent
            location="San Clemente"
            name="Trestles"
            photo={photoTrestles}
            startDate="2025-06-09"
            endDate="2025-06-15"
          />
          <WslEvent
            location="Tahiti"
            name="Tahiti Pro"
            photo={photoTahiti}
            startDate="2025-08-07"
            endDate="2025-08-16"
          />
          <WslEvent
            location="Fiji"
            name="WSL Finals Fiji"
            photo={photoFiji}
            startDate="2025-08-27"
            endDate="2025-09-04"
          />
        </div>
      </Container>
    </div>
  );
}
